import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import InputBase from "@material-ui/core/InputBase";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { useHistory } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import LinearProgress from "@mui/material/LinearProgress";

import Button from "@material-ui/core/Button";

import { Cores } from "../style/Color";

import api from "../api";

import { putManutencaoEntrega, putEntrega } from "../service/CRUD_Service";

export default function ListadeManutencaoEntrega() {
  const history = useHistory("");
  const [cargas, setCargas] = useState([]); //recebe do banco de dados
  const [ent, setEnt] = useState({});
  const classes = useRowStyles();
  const [searchId, setSearchId] = useState("");
  const [searchMotoristas, setSearchMotoristas] = useState("");
  const [searchPlacaCavalo, setSearchPlacaCavalo] = useState("");
  const [searchPlacaCarreta, setSearchPlacaCarreta] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [refresh, setRefresh] = useState(false); // Estado para forçar atualização
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [idToRemove, setIdToRemove] = useState([]);
  const [OrigErro, setOrigErro] = useState("");
  const [dataInicial, setDataInicial] = useState(
    new Date().toISOString().split("T")[0]
  ); // Data do dia
  const [dataFinal, setDataFinal] = useState(
    new Date().toISOString().split("T")[0]
  ); // Data do dia

  const cancelRemove = () => {
    setConfirmOpen(false); // Fecha o Alert sem excluir
    setIdToRemove(null);
  };

  const CancelarCarga = async (event) => {
    event.preventDefault();

    const payloadEntrega = {
      statusentrega: "Cancelado",
    };

    const payloadEntregaCarga = {
      statusentregacarga: "Cancelado",
    };
    // Certifique-se de que idToRemove seja um array
    const items = Array.isArray(idToRemove) ? idToRemove : [idToRemove];

    if (!items || items.length === 0) {
      console.log("Nenhum item encontrado para cancelar.");
      return;
    }

    try {
      for (const item of items) {
        // Cancela cada ID das `cviagens`
        if (item.cviagens && item.cviagens.length > 0) {
          for (const viagem of item.cviagens) {
            try {
              await putEntrega(viagem.id, payloadEntrega);
            } catch (err) {
              console.error(`Erro ao cancelar a viagem ${viagem.id}:`, err);
            }
          }
        } else {
          console.log(`Item ${item.id} não possui cviagens para cancelar.`);
        }

        // Cancela o ID principal após as `cviagens`
        try {
          await putManutencaoEntrega(item.id, payloadEntregaCarga);
        } catch (err) {
          console.error(`Erro ao cancelar o item principal ${item.id}:`, err);
        }
      }

      // Após todos os cancelamentos, atualiza a página e exibe sucesso
      setRefresh((prev) => !prev);
      setOpenSnackbar(true);
    } catch (err) {
      // Captura e exibe qualquer erro global
      console.error("Erro global ao processar cancelamentos:", err);
      setOrigErro(err);
    } finally {
      // Finaliza e redefine os estados
      setConfirmOpen(false);
      setIdToRemove(null);
    }
  };

  // Função para limpar os filtros
  const handleClearFilters = () => {
    setSearchId("");
    setSearchMotoristas("");
    setSearchPlacaCavalo("");
    setSearchPlacaCarreta("");
  };

  const BuscarEntregas = () => {
    api
      .get("ManutencaoEntregas", {
        params: {
          dataInicial, // Parâmetro enviado na query
          dataFinal, // Parâmetro enviado na query
        },
      })
      .then((res) => {
        let carg = res.data;
        // Função para ordenar a lista por hora
        const itens = carg.sort((a, b) => {
          const c = a.id;
          const d = b.id;
          return d - c; // Data mais antiga para mais nova
        });
        setCargas(itens);
      })
      .catch(() => alert("Erro ao carregar"));
  };

  const calcularProgresso = (cviagens) => {
    if (!cviagens || cviagens.length === 0) {
      return 0; // Retorna 0% caso não haja entregas
    }

    const total = cviagens.length;
    const entregasConcluidas = cviagens.filter(
      (viagem) => viagem.statusentrega === "Entregue"
    ).length;

    return (entregasConcluidas / total) * 100;
  };

  const onEdit = (id) => {
    history.push("/ManutencaoEntregaEdit", { id });
  };

  const onDelete = (id) => {
    setIdToRemove(id);
    setConfirmOpen(true); // Exibe o Alert de confirmação
  };

  const filtrarDados = (dados) => {
    return dados?.filter((item) => {
      const matchId = searchId === "" || item.id.toString().includes(searchId);
      const matchMotoristas =
        searchMotoristas === "" ||
        (item.fkmotorista.nome &&
          item.fkmotorista.nome
            .toLowerCase()
            .includes(searchMotoristas.toLowerCase()));
      const matchPlacaCavalo =
        searchPlacaCavalo === "" ||
        (item.fkveiculotrator.placa &&
          item.fkveiculotrator.placa
            .toLowerCase()
            .includes(searchPlacaCavalo.toLowerCase()));
      const matchPlacaCarreta =
        searchPlacaCarreta === "" ||
        (item.fkveiculo.placa &&
          item.fkveiculo.placa
            .toLowerCase()
            .includes(searchPlacaCarreta.toLowerCase()));

      return (
        matchId && matchMotoristas && matchPlacaCavalo && matchPlacaCarreta
      );
    });
  };

  let dados = filtrarDados(cargas);
  //EXIBE DADOS DA TABELA
  function Row(props) {
    const { row } = props;
    const [open, setOpen] = useState(false);
    const classes = useRowStyles();

    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            className={classes.txtOperacaotabela}
          >
            {row.id}
          </TableCell>

          <TableCell component="th" scope="row">
            {row?.fkmotorista?.nome}
          </TableCell>
          {/* {row.fkmotorista.tipo} */}
          <TableCell align="center">{row?.fkmotorista?.tipo}</TableCell>
          <TableCell align="center">{row?.fkveiculotrator?.placa}</TableCell>
          <TableCell align="center">{row?.fkveiculo?.placa}</TableCell>
          <TableCell align="center">{row?.fkveiculo?.nfrota}</TableCell>
          {(() => {
            // let origem = row.cviagens.fkorigem.descricao;
            let origem = [
              ...new Set(row?.cviagens?.map((x) => x?.fkorigem?.descricao)),
            ];

            return (
              <TableCell align="center" className={classes.txtOperacaotabela}>
                {origem}
              </TableCell>
            );
          })()}
          {/*STATUS DA CARGA ESTIVER VISUALIZADO A COR FICA AZUL,CASO CONTRA ROSA */}
          <TableCell align="center">
            {(() => {
              if (row.statusentregacarga == "Pendente") {
                return (
                  <Typography
                    style={{
                      backgroundColor: Cores.Amarelo1,
                      color: Cores.Branco1,
                      borderRadius: 5,
                    }}
                  >
                    {row.statusentregacarga}
                  </Typography>
                );
              } else if (row.statusentregacarga == "Entregue") {
                return (
                  <Typography
                    style={{
                      backgroundColor: Cores.Verde1,
                      color: Cores.Branco1,
                      borderRadius: 5,
                    }}
                  >
                    {row.statusentregacarga}
                  </Typography>
                );
              } else {
                return (
                  <Typography
                    style={{
                      backgroundColor: Cores.Vermelho1,
                      color: Cores.Branco1,
                      borderRadius: 5,
                    }}
                  >
                    {row.statusentregacarga}
                  </Typography>
                );
              }
            })()}
          </TableCell>

          {/*STATUS DO DOCUMENTO, SE O DOCUMENTO PENDENTE A COR FICA AZUL,CASO CONTRA ROSA */}
          <TableCell align="center">
            <Box display="flex" flexDirection="column" alignItems="center">
              {row.cviagens && row.cviagens.length > 0 ? (
                <>
                  <Typography variant="body2">{`${calcularProgresso(
                    row.cviagens
                  ).toFixed(0)}%`}</Typography>
                  <LinearProgress
                    variant="determinate"
                    value={calcularProgresso(row.cviagens)}
                    sx={{
                      width: "100%",
                      height: 10,
                      borderRadius: 5,
                      backgroundColor:
                        row.statusdoc === "Pendente"
                          ? Cores.Sinza2
                          : Cores.Vermelho2,
                    }}
                  />
                </>
              ) : (
                <Typography variant="body2">Sem entregas</Typography>
              )}
            </Box>
          </TableCell>
          {/*STATUS DA NF, SE ESTIVER COM NF A COR FICA AZUL,CASO CONTRA ROSA */}
          <TableCell align="center">
            <ButtonGroup>
              {/*BOTAO EDITAR */}

              <IconButton
                aria-label="Editar"
                style={{ width: 25 }}
                color="primary"
              >
                <EditIcon fontSize="small" onClick={() => onEdit(row.id)} />
              </IconButton>

              {/*BOTAO EXCLUIR   */}

              <IconButton
                aria-label="delete"
                style={{ width: 25 }}
                color="secondary"
              >
                <DeleteIcon
                  fontSize="small"
                  className="btexlcuir"
                  onClick={() => {
                    onDelete(row);
                  }}
                />
              </IconButton>
            </ButtonGroup>
          </TableCell>
        </TableRow>

        {/*EXIBI DETALHES DA CARGA */}
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Detalhes
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Lojas</TableCell>
                      <TableCell>Perfil</TableCell>
                      <TableCell align="center">Qtd P</TableCell>
                      <TableCell align="center">Cidade</TableCell>
                      <TableCell align="center">Status Entrega</TableCell>
                      <TableCell align="center">Data Entrega</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* segundo map */}
                    {row?.cviagens.map((valor) => (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {valor?.fkdestino?.lojas}
                        </TableCell>
                        <TableCell> {valor.perfil}</TableCell>
                        <TableCell align="center">{valor.qtdpallet}</TableCell>
                        <TableCell align="center">
                          {valor?.fkdestino?.municipio}
                        </TableCell>
                        <TableCell align="center">
                          {(() => {
                            if (valor.statusentrega == "Pendente") {
                              return (
                                <Typography
                                  style={{
                                    backgroundColor: Cores.Amarelo1,
                                    color: Cores.Branco1,
                                    borderRadius: 5,
                                  }}
                                >
                                  {valor.statusentrega}
                                </Typography>
                              );
                            } else if (valor.statusentrega == "Entregue") {
                              return (
                                <Typography
                                  style={{
                                    backgroundColor: Cores.Verde1,
                                    color: Cores.Branco1,
                                    borderRadius: 5,
                                  }}
                                >
                                  {valor.statusentrega}
                                </Typography>
                              );
                            } else {
                              return (
                                <Typography
                                  style={{
                                    backgroundColor: Cores.Vermelho1,
                                    color: Cores.Branco1,
                                    borderRadius: 5,
                                  }}
                                >
                                  {valor.statusentrega}
                                </Typography>
                              );
                            }
                          })()}
                        </TableCell>
                        <TableCell align="center">
                          {(() => {
                            let data = valor.dataentrega;
                            let horas = new Date(data);
                            let [date, month, year] = new Date(data)
                              .toLocaleDateString("pt-BR")
                              .split("/");
                            let formatadata =
                              date +
                              "/" +
                              month +
                              "/" +
                              year +
                              " " +
                              horas.getHours() +
                              ":" +
                              horas.getMinutes() +
                              ":" +
                              horas.getSeconds();
                            let sem = "";
                            if (data === null) {
                              return sem;
                            } else {
                              return formatadata;
                            }
                          })()}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <div>
      {/* Snackbar de erro */}
      {confirmOpen && (
        <Alert
          variant="filled"
          severity="info"
          sx={{
            position: "fixed",
            top: "50%", // Alinha o alerta verticalmente no centro
            left: "50%", // Alinha o alerta horizontalmente no centro
            transform: "translate(-50%, -50%)", // Ajusta para centralizar completamente
            zIndex: 9999,
            width: "90%", // Opcional: ajusta o tamanho do alerta
            maxWidth: 600, // Opcional: define largura máxima
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span style={{ marginRight: 45 }}>
              Tem certeza que deseja cancelar este item?
            </span>
            <p></p>
            <div>
              <button
                onClick={CancelarCarga}
                style={{
                  marginRight: 8,
                  background: "#d32f2f",
                  color: "#fff",
                  border: "none",
                  padding: "6px 12px",
                  cursor: "pointer",
                  borderRadius: 4,
                }}
              >
                Confirmar
              </button>
              <button
                onClick={cancelRemove}
                style={{
                  background: "#1976d2",
                  color: "#fff",
                  border: "none",
                  padding: "6px 12px",
                  cursor: "pointer",
                  borderRadius: 4,
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </Alert>
      )}
      <Paper
        style={{
          height: 50,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          paddingLeft: 10,
          marginBottom: 10,
        }}
      >
        {/* InputBase para cada atributo de pesquisa */}
        <InputBase
          style={{
            marginBlockEnd: 5,

            color: "default",
            height: 35,
            width: 200,
            backgroundColor: "#E4E7E8",
          }}
          type="text"
          className="form-control text-center input-text"
          placeholder="Pesquisar ID"
          value={searchId}
          onChange={(e) => setSearchId(e.target.value)}
        />

        <InputBase
          style={{
            marginBlockEnd: 5,
            marginLeft: 14,
            color: "default",
            height: 35,
            width: 200,
            backgroundColor: "#E4E7E8",
          }}
          type="text"
          className="form-control text-center input-text"
          placeholder="Pesquisar motoristas"
          value={searchMotoristas}
          onChange={(e) => setSearchMotoristas(e.target.value)}
        />

        <InputBase
          style={{
            marginBlockEnd: 5,
            marginLeft: 14,
            color: "default",
            height: 35,
            width: 200,
            backgroundColor: "#E4E7E8",
          }}
          type="text"
          className="form-control text-center input-text"
          placeholder="Pesquisar Placa cavalo"
          value={searchPlacaCavalo}
          onChange={(e) => setSearchPlacaCavalo(e.target.value)}
        />
        <InputBase
          style={{
            marginBlockEnd: 5,
            marginLeft: 14,
            color: "default",
            height: 35,
            width: 200,
            backgroundColor: "#E4E7E8",
          }}
          type="text"
          className="form-control text-center input-text"
          placeholder="Pesquisar Placa carreta"
          value={searchPlacaCarreta}
          onChange={(e) => setSearchPlacaCarreta(e.target.value)}
        />
        {/* Seletor de período */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",

            alignItems: "center",
          }}
        >
          <Typography
            style={{
              fontSize: 14,
              color: Cores.Sinza1,
              marginLeft: 10,
              marginRight: 3,
            }}
          >
            Data Inicial:
          </Typography>
          <input
            style={{ height: 35 }}
            type="date"
            value={dataInicial}
            onChange={(e) => setDataInicial(e.target.value)}
          />
          <Typography
            style={{
              fontSize: 14,
              color: Cores.Sinza1,
              marginLeft: 10,
              marginRight: 3,
            }}
          >
            Data Final:
          </Typography>

          <input
            style={{ height: 35 }}
            type="date"
            value={dataFinal}
            onChange={(e) => setDataFinal(e.target.value)}
          />

          <Button
            style={{
              marginLeft: 1,
              height: 35,
              width: 50,
              backgroundColor: Cores.Verde,
              color: "#fff",
              fontSize: 12,
            }}
            onClick={BuscarEntregas}
          >
            Buscar
          </Button>
        </div>

        {/* Botão para limpar filtros */}
        <Button
          variant="contained"
          onClick={handleClearFilters}
          style={{
            marginLeft: 14,
            height: 35,
            width: 150,
            backgroundColor: "#e57373",
            color: "#fff",
            fontSize: 12,
          }}
        >
          Limpar Filtros
        </Button>
      </Paper>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead className={classes.head}>
            <TableRow>
              <TableCell />
              <TableCell className={classes.txtheard}>Id Carga</TableCell>
              <TableCell className={classes.txtheard}>Motoristas</TableCell>

              <TableCell align="center" className={classes.txtheard}>
                Perfil Mt
              </TableCell>
              <TableCell align="center" className={classes.txtheard}>
                Placa Cavalo
              </TableCell>
              <TableCell align="center" className={classes.txtheard}>
                Placa Carreta
              </TableCell>
              <TableCell align="center" className={classes.txtheard}>
                SR
              </TableCell>
              <TableCell align="center" className={classes.txtheard}>
                Operacao
              </TableCell>
              <TableCell align="center" className={classes.txtheard}>
                Status Carga
              </TableCell>
              <TableCell align="center" className={classes.txtheard}>
                Progresso Entrega
              </TableCell>
              <TableCell align="center" className={classes.txtheard}>
                Acoes
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dados?.map((row) => (
              <Row row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
//styles
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  head: {
    backgroundColor: "#FFFFFF",
  },
  txtheard: {
    fontWeight: "bold",
  },

  txtOperacaotabela: {
    fontSize: 16,
    color: Cores.Vermelho1,
    fontFamily: "inherit",
    fontWeight: "bold",
  },
});
