import React, { useState, useEffect } from "react";

import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import FormControl from "@material-ui/core/FormControl";

import { colors, Modal, Typography } from "@material-ui/core"; // Exemplo com Material-UI

import Paper from "@material-ui/core/Paper";
import Style_login from "../style/style_login.css";
import { BsSearch } from "react-icons/bs";

import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import InputBase from "@material-ui/core/InputBase";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";

import TableRow from "@material-ui/core/TableRow";

import moment from "moment";
//import momentDurationFormatSetup from 'moment-duration-format';
import "moment-duration-format";

import api from "../api";
import {
  getManutencaoEntregaid,
  putManutencaoEntrega,
  deleteManutencaoEntrega,
  putEntrega,
} from "../service/CRUD_Service";
import { Cores } from "../style/Color";

import Barra from "./barra_motorista";

export default function ManutencaoEntregaEdit({ history }) {
  // Definição dos estados usando useState

  const { id } = history.location.state;
  const [idprogramacao, setIdProgramacao] = useState("");
  const [programacaocarga, setIdProgramacaocarga] = useState("");
  const [motorista, setMotorista] = useState("");
  const [nomeMotorista, setNomeMotorista] = useState("");
  const [idveiculotrator, setIdVeiculoTrator] = useState("");
  const [nomeVeiculoTrator, setNomeVeiculoTrator] = useState("");
  const [veiculo, setVeiculo] = useState("");
  const [ncarga, setNcarga] = useState("");
  const [statussolicitamdfe, setStatussolicitamdfe] = useState("");
  const [datamdfe, setDatamdfe] = useState("");
  const [statuspredoc, setStatuspredoc] = useState("");
  const [dtpredoc, setDtpredoc] = useState("");
  const [statusnf, setStatusnf] = useState("");
  const [dtstatusnf, setDtstatusnf] = useState("");
  const [statusprocessamento, setStatusprocessamento] = useState("");
  const [dtstatusprocessamento, setDtstatusprocessamento] = useState("");
  const [statusdoc, setStatusdoc] = useState("");
  const [dtstatusdoc, setDatastatusdoc] = useState("");
  const [statusentregacarga, setStatusentregacarga] = useState("");
  const [dtentregacarga, setDtentregacarga] = useState("");
  const [dataentregacargapadrao, setDtentregacargapadrao] = useState();
  const [statusentrega, setStatusentrega] = useState("");
  const [dataentrega, setDataentrega] = useState("");
  const [dataentregapadrao, setDataentregapadrao] = useState();
  const [identrega, setIdentrega] = useState("");

  const [nomeunidadecarga, setNomeUnidadeCarga] = useState("");
  const [names, setNames] = useState([]); // Armazena os dados retornados da API
  const [veiculotrator, setVeiculoTrator] = useState([]);
  const [unidadeCarga, setUnidadeCarga] = useState([]);
  const [listaEntregas, setListaEntregas] = useState([]);
  const [origem, setOrigem] = useState([]);
  const [idorigem, setIdOrigem] = useState("");
  const [nomeOrigem, setNomeOrigem] = useState("");
  const [destinos, setDestinos] = useState([]);
  const [iddestinos, setIdDestinos] = useState("");
  const [nomedestinos, setNomeDestinos] = useState("");
  const [perfil, setPerfil] = useState([]);
  const [idperfil, setIdPerfil] = useState("");
  const [nomePerfil, setNomePerfil] = useState("");
  const [qtdpl, setQtdPl] = useState("");
  const [loading, setLoading] = useState(true); // Para exibir carregamento enquanto a API responde
  const [showModal, setShowModal] = useState(false); // Controla a visibilidade do modal
  const [modalVeiculoAberto, setModalVeiculoAberto] = useState(false); // Controle do modal de veículo
  const [showmodalunidadecarga, setShowModalUnidadeCarga] = useState(false);
  const [showModalOrigem, setShowModalOrigem] = useState(false);
  const [showModalDestinos, setShowModalDestinos] = useState(false);
  const [showModalPerfil, setShowModalPerfil] = useState(false);
  const [showModalEntregas, setShowModalEntregas] = useState(false);
  const [filtroPlaca, setFiltroPlaca] = useState("");
  const [filtroUnidadecarga, setFiltroUnidadeCarga] = useState("");
  const [filtramotorista, setFiltraMotorista] = useState("");
  const [filtroOrigem, setFiltroOrigem] = useState("");
  const [filtroDestinos, setFiltroDestinos] = useState("");
  const [filtroPerfil, setFiltroPerfil] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [OrigErro, setOrigErro] = useState("");
  const [refresh, setRefresh] = useState(false); // Estado para forçar atualização
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [idToRemove, setIdToRemove] = useState(null);

  // Função que ajusta a data e hora para o fuso horário correto
  const formatData = (datasaida) => {
    // Ajusta o fuso horário de UTC para UTC -3 (Horário de Brasília)

    return moment(datasaida).utc().format("YYYY-MM-DDTHH:mm");
  };

  useEffect(() => {
    getManutencaoEntregaid(id)
      .then((res) => {
        setIdProgramacaocarga(res);
        setMotorista(res.motorista);
        setNomeMotorista(res.fkmotorista.nome);
        setIdVeiculoTrator(res.fkveiculotrator.id);
        setNomeVeiculoTrator(res.fkveiculotrator.placa);
        setVeiculo(res.fkveiculo.id);
        setNomeUnidadeCarga(res.fkveiculo.placa);
        setListaEntregas(res.cviagens);
        setNcarga(res.ncarga);
        setStatussolicitamdfe(res.statussolicitamdfe);
        setDatamdfe(formatData(res.datasolicitamdfe));
        setStatuspredoc(res.statuspredoc);
        setDtpredoc(formatData(res.datapredoc));
        setStatusnf(res.statusnf);
        setDtstatusnf(formatData(res.dtstatusnf));
        setStatusprocessamento(res.statusprocessamento);
        setDtstatusprocessamento(formatData(res.datastatusprocessamento));
        setStatusdoc(res.statusdoc);
        setDatastatusdoc(formatData(res.datastatusdoc));
        setStatusentregacarga(res.statusentregacarga);
        setDtentregacarga(formatData(res.dataentregacarga));
        setDtentregacargapadrao(res.dataentregacargapadrao);
      })
      .catch((err) => setOrigErro(err));
  }, [id, refresh]);

  let Perfil = [
    {
      id: "1",
      perfil: "Normal",
    },
    {
      id: "2",
      perfil: "Horti",
    },
    {
      id: "3",
      perfil: "Frios",
    },
    {
      id: "4",
      perfil: "Panificacao Frios",
    },
    {
      id: "5",
      perfil: "Panificacao Seca",
    },
  ];

  // Função para fechar o Snackbar de sucesso
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    // Redirecionar após o tempo do Snackbar
    setTimeout(() => {
      // window.location = "/ListaProgramacao"; // Redireciona para a página desejada
    }, 2000); // Tempo de duração do Snackbar (em milissegundos)

    setOpenSnackbar(false);
  };

  // Função para preencher a lista
  const preencherLista = (event) => {
    event.preventDefault();

    const payloadEntrega = {
      viagem: id,
      origem: idorigem,
      numeroloja: iddestinos,
      perfil: nomePerfil,
      qtdpallet: qtdpl,
      statusentrega: "Pendente",
    };

    api
      .post("/entregas", payloadEntrega)
      .then(() => {
        setRefresh((prev) => !prev); // Alterna o valor de refresh
        // Exibe o Snackbar de sucesso
        setOpenSnackbar(true);
      })
      .catch((err) => {
        setOrigErro(err);
        console.error("Erro ao salvar:", err);
      });
  };

  // Função para preencher a lista
  const CancelarEntrega = (event) => {
    event.preventDefault();

    const payloadEntrega = {
      viagem: null,
      statusentrega: "Cancelado",
    };

    putEntrega(idToRemove, payloadEntrega)
      .then(() => {
        setRefresh((prev) => !prev); // Alterna o valor de refresh
        // Exibe o Snackbar de sucesso
        setOpenSnackbar(true);
      })
      .catch((err) => {
        setOrigErro(err);
        console.error("Erro ao salvar:", err);
      })
      .finally(() => {
        setConfirmOpen(false); // Fecha o Alert após a exclusão
        setIdToRemove(null);
      });
  };

  const removerItem = (idItem) => {
    setIdToRemove(idItem);
    setConfirmOpen(true); // Exibe o Alert de confirmação
  };

  const EditEntrega = (item) => {
    setLoading(true);
    setShowModalEntregas(true);
    setNomeOrigem(item.fkorigem.descricao);
    setNomeDestinos(item.fkdestino.razao);
    setNomePerfil(item.perfil);
    setQtdPl(item.qtdpallet);
    setStatusentrega(item.statusentrega);
    setDataentrega(formatData(item.dataentrega));
    setDataentregapadrao(item.dataentregapadrao);
    setIdentrega(item.id);
  };

  const FecharModalEntrega = () => {
    setShowModalEntregas(false);
  };

  const SalvarAlteracaoEntrega = () => {
    const payloadEntrega = {
      perfil: nomePerfil,
      qtdpallet: qtdpl,
      statusentrega: statusentrega,
      dataentrega: statusentrega == "Pendente" ? null : new Date(dataentrega),
      dataentregapadrao:
        statusentrega == "Pendente" ? null : new Date(dataentregapadrao),
    };

    putEntrega(identrega, payloadEntrega)
      .then(() => setRefresh((prev) => !prev))
      .catch((err) => setOrigErro(err));
    setShowModalEntregas(false);
  };

  const cancelRemove = () => {
    setConfirmOpen(false); // Fecha o Alert sem excluir
    setIdToRemove(null);
  };

  // Manipulador para atualizar o estado com base no evento de input
  const changeHandler = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "idorigem":
        setIdOrigem(value);
        break;
      case "nomeOrigem":
        setNomeOrigem(value);
        break;
      case "iddestinos":
        setIdDestinos(value);
        break;
      case "nomedestinos":
        setNomeDestinos(value);
        break;
      case "nomePerfil":
        setNomePerfil(value);
        break;
      case "ncarga":
        setNcarga(value);
        break;
      case "statussolicitamdfe":
        setStatussolicitamdfe(value);
        break;
      case "statuspredoc":
        setStatuspredoc(value);
        break;
      case "qtdpl":
        setQtdPl(value);
        break;
      case "statusnf":
        setStatusnf(value);
        break;
      case "statusprocessamento":
        setStatusprocessamento(value);
        break;
      case "statusdoc":
        setStatusdoc(value);
        break;
      case "statusentregacarga":
        setStatusentregacarga(value);
        break;
      case "statusentrega":
        setStatusentrega(value);
        break;
      default:
        break;
    }
  };

  // Função para submeter o formulário
  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      const payloadCarga = {
        motorista,
        idveiculotrator,
        veiculo,
        ncarga,
        statussolicitamdfe,
        statuspredoc,
      };

      const responseCarga = await api.post("/ManutencaoEntregas", payloadCarga);
      const idProgramacaoCarga = responseCarga.data.id;

      {
        /*}
      const entregasPayload = listaEntregas.map((entrega) => ({
        fkprogramacaocarga: idProgramacaoCarga,
        idorigem: entrega.idorigem,
        iddestino: entrega.iddestinos,
        perfil: entrega.nomePerfil,
        qtdpallet: entrega.qtdpl,
      }));

      for (const entrega of entregasPayload) {
        await api.post("/programacaoentregas", entrega);
      }
        */
      }

      setOpenSnackbar(true);
    } catch (error) {
      console.error(
        "Erro ao salvar a programação de carga ou entregas:",
        error
      );
      alert("Erro ao salvar. Verifique os dados e tente novamente.");
    }
  };

  // Função para formatar uma data para o formato YYYY-MM-DD
  function Datanormal(dateParam) {
    // Se dataParam não for passado, usa a data atual
    const date = dateParam ? moment(dateParam) : moment();

    // Formata a data no formato desejado (YYYY-MM-DD)
    return date.format("YYYY-MM-DD");
  }

  const toEditProgramacao = (event) => {
    event.preventDefault();

    const payloadCarga = {
      motorista,
      idveiculotrator,
      veiculo,
      ncarga,
      statussolicitamdfe,
      datasolicitamdfe: new Date(datamdfe), // Exemplo de data
      statuspredoc,
      datapredoc: new Date(dtpredoc),
      statusnf,
      datastatusnf: new Date(dtstatusnf),
      statusprocessamento,
      datastatusprocessamento: new Date(dtstatusprocessamento),
      statusdoc,
      datastatusdoc: new Date(dtstatusdoc),
      statusentregacarga,
      dataentregacarga:
        statusentregacarga == "Pendente" ? null : new Date(dtentregacarga),
      dataentregacargapadrao:
        statusentregacarga == "Pendente"
          ? null
          : new Date(dataentregacargapadrao),
    };

    putManutencaoEntrega(id, payloadCarga)
      .then(() => history.push("/ManutencaoEntrega"))
      .catch((err) => setOrigErro(err));
  };

  // Função para buscar motoristas
  const fetchMotoristas = () => {
    setLoading(true);
    api
      .get("/MotoristamanutencaoControllers")
      .then((res) => {
        setNames(res.data);
        setLoading(false);
        setShowModal(true);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  // Selecionar motorista
  const handleSelectMotorista = (id, nome) => {
    setMotorista(id);
    setNomeMotorista(nome);
    setShowModal(false);
  };

  // Função para buscar veículos trator
  const fetchVeiculotrator = () => {
    setLoading(true);
    api
      .get("/veiculos")
      .then((res) => {
        setVeiculoTrator(res.data);
        setLoading(false);
        setModalVeiculoAberto(true);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const onChangedataentrega = (event) => {
    setDataentrega(event.target.value);
  };

  const onChangedataentregapadrao = (event) => {
    setDataentregapadrao(event.target.value);
  };
  const onChangestatussolicitamdfe = (event) => {
    setStatussolicitamdfe(event.target.value);
  };

  const onChangedtmdf = (event) => {
    setDatamdfe(event.target.value);
  };

  const onChangepredoc = (event) => {
    setDtpredoc(event.target.value);
  };
  const onChangedtnf = (event) => {
    setDtstatusnf(event.target.value);
  };

  const onChangedtprocessamento = (event) => {
    setDtstatusprocessamento(event.target.value);
  };

  const onChangedtstatusdoc = (event) => {
    setDatastatusdoc(event.target.value);
  };
  const onChangedtentregacarga = (event) => {
    setDtentregacarga(event.target.value);
  };

  const onChangedtentregacargapadrao = (event) => {
    setDtentregacargapadrao(event.target.value);
  };

  // Função para buscar veículos trator
  const fetchVeiculounidadecarga = () => {
    setLoading(true);
    api
      .get("/veiculos")
      .then((res) => {
        setUnidadeCarga(res.data);
        setLoading(false);
        setShowModalUnidadeCarga(true);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const handleSelectVeiculotrator = (id, nome) => {
    setIdVeiculoTrator(id);
    setNomeVeiculoTrator(nome);
    setModalVeiculoAberto(false);
  };

  const handleSelectunidadecarga = (id, nome) => {
    setVeiculo(id);
    setNomeUnidadeCarga(nome);
    setShowModalUnidadeCarga(false);
  };

  // Função para buscar veículos trator
  const fetchOrigens = () => {
    setLoading(true);
    api
      .get("/origem")
      .then((res) => {
        setOrigem(res.data);
        setLoading(false);
        setShowModalOrigem(true);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const handleSelectOrigem = (id, nome) => {
    setIdOrigem(id);
    setNomeOrigem(nome);
    setShowModalOrigem(false);
  };

  const handleFiltroOrigemChange = (e) => {
    setFiltroOrigem(e.target.value);
  };
  const handlefiltroDestinosChange = (e) => {
    setFiltroDestinos(e.target.value);
  };

  // Função para buscar veículos trator
  const fetchDestinos = () => {
    setLoading(true);
    api
      .get("/destino")
      .then((res) => {
        setDestinos(res.data);
        setLoading(false);
        setShowModalDestinos(true);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };
  const handleSelectDestinos = (id, nome) => {
    setIdDestinos(id);
    setNomeDestinos(nome);
    setShowModalDestinos(false);
  };

  const fetchPerfil = () => {
    setLoading(true);

    setTimeout(() => {
      setPerfil(Perfil); // Define os dados como sendo o array Perfil
      setLoading(false);
      setShowModalPerfil(true); // Exibe modal após carregar os dados
    }, 500);
  };
  const handleSelectPerfil = (id, nome) => {
    setIdPerfil(id);
    setNomePerfil(nome);
    setShowModalPerfil(false);
  };

  const handlefiltroPerfilChange = (e) => {
    setFiltroPerfil(e.target.value);
  };

  const FecharModalMotorista = (e) => {
    setShowModal(false);
  };

  const FecharModalVeiculotrator = (e) => {
    setModalVeiculoAberto(false);
  };
  const FecharModalunidadecarga = (e) => {
    setShowModalUnidadeCarga(false);
  };

  return (
    <body className={Style_login.body}>
      <div>
        <Barra />
        <div style={{ marginTop: 50, padding: 20 }}>
          <Paper
            variant="outlined"
            elevation={4}
            style={{
              backgroundColor: "#fff",

              //   maxHeight: 800, // Limita a altura máxima
              overflow: "hidden", // Evita rolagem externa
              margin: "auto", // Centraliza o Paper na tela
              borderRadius: "8px",
              display: "flex", // Torna o conteúdo interno flexível
              flexDirection: "column", // Organiza os elementos verticalmente
            }}
          >
            <form
              style={
                {
                  // backgroundColor: "red",
                }
              }
              onSubmit={submitHandler}
            >
              <div
                style={{
                  justifyItems: "center",
                }}
              >
                <Snackbar
                  open={openSnackbar}
                  autoHideDuration={2000}
                  onClose={handleCloseSnackbar}
                >
                  <Alert
                    onClose={handleCloseSnackbar}
                    severity="success"
                    variant="filled"
                    sx={{
                      width: "100%",
                      maxWidth: 300,
                      position: "fixed",
                      left: "50%",
                      bottom: 20,
                      transform: "translateX(-50%)",
                      zIndex: 9999,
                    }}
                  >
                    Processo executado com sucesso!
                  </Alert>
                </Snackbar>
                {/* Snackbar de erro */}
                {confirmOpen && (
                  <Alert
                    variant="filled"
                    severity="info"
                    sx={{
                      position: "fixed",
                      top: "50%", // Alinha o alerta verticalmente no centro
                      left: "50%", // Alinha o alerta horizontalmente no centro
                      transform: "translate(-50%, -50%)", // Ajusta para centralizar completamente
                      zIndex: 9999,
                      width: "90%", // Opcional: ajusta o tamanho do alerta
                      maxWidth: 600, // Opcional: define largura máxima
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ marginRight: 45 }}>
                        Tem certeza que deseja excluir este item?
                      </span>
                      <p></p>
                      <div>
                        <button
                          onClick={CancelarEntrega}
                          style={{
                            marginRight: 8,
                            background: "#d32f2f",
                            color: "#fff",
                            border: "none",
                            padding: "6px 12px",
                            cursor: "pointer",
                            borderRadius: 4,
                          }}
                        >
                          Confirmar
                        </button>
                        <button
                          onClick={cancelRemove}
                          style={{
                            background: "#1976d2",
                            color: "#fff",
                            border: "none",
                            padding: "6px 12px",
                            cursor: "pointer",
                            borderRadius: 4,
                          }}
                        >
                          Cancelar
                        </button>
                      </div>
                    </div>
                  </Alert>
                )}

                <InputLabel
                  style={{ fontSize: 25, marginTop: 15 }}
                  id="demo-simple-select-helper-label"
                >
                  Manutencao de Carga e entregas
                </InputLabel>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 25,
                }}
              >
                <div
                  style={{
                    display: "flex", // Permite que os itens fiquem lado a lado
                    flexDirection: "row", // Garante alinhamento horizontal
                    gap: 10, // Espaçamento entre os itens
                    marginLeft: 10,
                    marginRight: 20,
                  }}
                >
                  <InputBase
                    style={{
                      width: 80,
                      backgroundColor: Cores.bgInpubase,
                      height: 35,
                    }}
                    className="form-control text-center input-text"
                    placeholder="00"
                    type="number"
                    name="Motorista"
                    value={motorista}
                    onChange={changeHandler}
                  />

                  <InputBase
                    style={{
                      width: 370,
                      width: 250,
                      height: 35,
                      backgroundColor: Cores.bgInpubase,
                    }}
                    className="form-control text-center input-text"
                    placeholder="Selecione um motorista"
                    value={nomeMotorista}
                    InputProps={{
                      readOnly: true, // Campo somente leitura
                    }}
                  />

                  {/* Botão para abrir o modal */}
                  <Button
                    variant="contained"
                    onClick={fetchMotoristas}
                    style={{
                      marginLeft: -6,
                      height: 34,
                      backgroundColor: Cores.bgBotoesNovo,
                      color: Cores.clBotoesNovo,
                    }}
                  >
                    <BsSearch
                      color={Cores.clBotoesNovo}
                      onClick={fetchMotoristas}
                      style={{ fontSize: 20 }}
                    />
                  </Button>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 15, // Espaçamento entre linhas
                    marginLeft: 10,
                  }}
                >
                  <div
                    style={{
                      display: "flex", // Permite que os itens fiquem lado a lado
                      flexDirection: "row", // Garante alinhamento horizontal
                      alignItems: "center", // Alinha verticalmente
                      gap: 10, // Espaçamento entre os itens
                    }}
                  >
                    <InputBase
                      style={{
                        width: 80,
                        height: 35,
                        backgroundColor: Cores.bgInpubase,
                      }}
                      placeholder="00"
                      className="form-control text-center input-text"
                      type="number"
                      name="Placa trator"
                      value={idveiculotrator}
                      onChange={changeHandler}
                    />

                    {/* Nome do Motorista */}
                    <InputBase
                      style={{
                        width: 250,
                        height: 35,
                        backgroundColor: Cores.bgInpubase,
                      }}
                      className="form-control text-center input-text"
                      placeholder="Selecione um veiculo trator"
                      value={nomeVeiculoTrator}
                      InputProps={{
                        readOnly: true, // Campo somente leitura
                      }}
                    />

                    {/* Botão para abrir o modal */}
                    <Button
                      variant="contained"
                      style={{
                        marginLeft: -7,
                        height: 34,
                        backgroundColor: Cores.bgBotoesNovo,
                        color: Cores.clBotoesNovo,
                      }}
                    >
                      <BsSearch
                        color={Cores.clBotoesNovo}
                        onClick={fetchVeiculotrator}
                        style={{ fontSize: 20 }}
                      />
                    </Button>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 15, // Espaçamento entre linhas
                    marginRight: 20,
                    marginLeft: 10,
                  }}
                >
                  <div
                    style={{
                      display: "flex", // Permite que os itens fiquem lado a lado
                      flexDirection: "row", // Garante alinhamento horizontal
                      alignItems: "center", // Alinha verticalmente
                      gap: 10, // Espaçamento entre os itens
                    }}
                  >
                    <InputBase
                      style={{
                        width: 80,
                        height: 35,

                        backgroundColor: Cores.bgInpubase,
                      }}
                      placeholder="00"
                      className="form-control text-center input-text"
                      type="number"
                      name="Placa Unidade carga"
                      value={veiculo}
                      onChange={changeHandler}
                    />
                  </div>

                  <InputBase
                    style={{
                      width: 250,
                      height: 35,
                      marginLeft: -4,
                      backgroundColor: Cores.bgInpubase,
                    }}
                    placeholder="Selecione unidade carga"
                    className="form-control text-center input-text"
                    value={nomeunidadecarga}
                    InputProps={{
                      readOnly: true, // Campo somente leitura
                    }}
                  />

                  {/* Botão para abrir o modal */}
                  <Button
                    variant="contained"
                    style={{
                      marginLeft: -13,

                      height: 34,
                      backgroundColor: Cores.bgBotoesNovo,
                      color: Cores.clBotoesNovo,
                    }}
                  >
                    <BsSearch
                      color={Cores.clBotoesNovo}
                      onClick={fetchVeiculounidadecarga}
                      style={{ fontSize: 20 }}
                    />
                  </Button>
                </div>

                <div
                  style={{
                    display: "flex", // Permite que os itens fiquem lado a lado
                    flexDirection: "row", // Garante alinhamento horizontal
                    alignItems: "center", // Alinha verticalmente
                    gap: 10, // Espaçamento entre os itens
                    marginLeft: 15,
                  }}
                >
                  <Typography
                    style={{
                      color: Cores.Sinza1,
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    Numero Carga
                  </Typography>
                  <InputBase
                    style={{
                      width: 100,
                      height: 35,

                      backgroundColor: Cores.bgInpubase,
                    }}
                    placeholder="00"
                    className="form-control text-center input-text"
                    type="number"
                    name="ncarga"
                    value={ncarga}
                    onChange={changeHandler}
                  />
                </div>
              </div>
              {/*LINHA DOIS  */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 25,
                }}
              >
                {/* Status MDFE */}
                <div
                  style={{
                    display: "flex", // Permite que os itens fiquem lado a lado
                    flexDirection: "row", // Garante alinhamento horizontal
                    alignItems: "center", // Alinha verticalmente
                    gap: 10, // Espaçamento entre os itens
                    marginLeft: 15,
                  }}
                >
                  <FormControl
                    variant="filled"
                    style={{
                      minWidth: 150,
                      flex: 1,
                    }}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Status MDFE
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      margin="dense"
                      label="statussolicitamdfe"
                      name="statussolicitamdfe"
                      value={statussolicitamdfe}
                      onChange={onChangestatussolicitamdfe}
                    >
                      <MenuItem value="">
                        <em>Status.....</em>
                      </MenuItem>

                      <MenuItem value={"Pendente"}>Pendente</MenuItem>
                      <MenuItem value={"Solicitado"}>Solicitado</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div
                  style={{
                    alignItems: "center", // Alinha verticalmente
                    gap: 10, // Espaçamento entre os itens
                    marginLeft: 15,
                  }}
                >
                  <TextField
                    name="datasolicitamdfe"
                    id="datetime-local"
                    label="Data e Solicitação MDFE"
                    variant="filled"
                    margin="dense"
                    type="datetime-local"
                    value={datamdfe}
                    onChange={onChangedtmdf}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{
                      width: 233,
                    }}
                  />
                </div>

                {/*SITUACAO KMM */}
                <div
                  style={{
                    display: "flex", // Permite que os itens fiquem lado a lado
                    flexDirection: "row", // Garante alinhamento horizontal
                    alignItems: "center", // Alinha verticalmente
                    gap: 10, // Espaçamento entre os itens
                    marginLeft: 27,
                  }}
                >
                  <FormControl
                    variant="filled"
                    style={{
                      minWidth: 150,
                      flex: 1,
                    }}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Situacao KMM
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      margin="dense"
                      label="statuspredoc"
                      name="statuspredoc"
                      value={statuspredoc}
                      onChange={changeHandler}
                    >
                      <MenuItem value="">
                        <em>Status.....</em>
                      </MenuItem>

                      <MenuItem value={"Pendente"}>Pendente</MenuItem>
                      <MenuItem value={"gerado"}>gerado</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    name="datapredoc"
                    id="datetime-local"
                    label="Data Situacao Kmm"
                    variant="filled"
                    margin="dense"
                    type="datetime-local"
                    value={dtpredoc}
                    onChange={onChangepredoc}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{
                      width: 233,
                    }}
                  />
                </div>

                {/*SITUACAO NF */}
                <div
                  style={{
                    display: "flex", // Permite que os itens fiquem lado a lado
                    flexDirection: "row", // Garante alinhamento horizontal
                    alignItems: "center", // Alinha verticalmente
                    gap: 10, // Espaçamento entre os itens
                    marginLeft: 27,
                  }}
                >
                  <FormControl
                    variant="filled"
                    style={{
                      minWidth: 150,
                      flex: 1,
                    }}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Status NF
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      margin="dense"
                      label="statusnf"
                      name="statusnf"
                      value={statusnf}
                      onChange={changeHandler}
                    >
                      <MenuItem value="">
                        <em>Status.....</em>
                      </MenuItem>

                      <MenuItem value={"Sem NF"}>Sem NF</MenuItem>
                      <MenuItem value={"Com NF"}>Com NF</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    name="datastatusnf"
                    id="datetime-local"
                    label="Data status NF"
                    variant="filled"
                    margin="dense"
                    type="datetime-local"
                    value={dtstatusnf}
                    onChange={onChangedtnf}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{
                      width: 245,
                    }}
                  />
                </div>

                {/*SITUACAO STATUS PROCESSAMENTO */}
                <div
                  style={{
                    display: "flex", // Permite que os itens fiquem lado a lado
                    flexDirection: "row", // Garante alinhamento horizontal
                    alignItems: "center", // Alinha verticalmente
                    gap: 10, // Espaçamento entre os itens
                    marginLeft: 27,
                  }}
                >
                  <FormControl
                    variant="filled"
                    style={{
                      minWidth: 180,
                      flex: 1,
                    }}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Status Processamento
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      margin="dense"
                      label="statusprocessamento"
                      name="statusprocessamento"
                      value={statusprocessamento}
                      onChange={changeHandler}
                    >
                      <MenuItem value="">
                        <em>Status.....</em>
                      </MenuItem>

                      <MenuItem value={"Pendente"}>Pendente</MenuItem>
                      <MenuItem value={"Visualizado"}>Visualizado</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    name="dtstatusprocessamento"
                    id="datetime-local"
                    label="Data status Processamento"
                    variant="filled"
                    margin="dense"
                    type="datetime-local"
                    value={dtstatusprocessamento}
                    onChange={onChangedtprocessamento}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{
                      width: 245,
                    }}
                  />
                </div>
              </div>
              {/*LINHA DOIS  */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 25,
                }}
              >
                {/*SITUACAO STATUS DOC */}
                <div
                  style={{
                    display: "flex", // Permite que os itens fiquem lado a lado
                    flexDirection: "row", // Garante alinhamento horizontal
                    alignItems: "center", // Alinha verticalmente
                    gap: 10, // Espaçamento entre os itens
                    marginLeft: 10,
                  }}
                >
                  <FormControl
                    variant="filled"
                    style={{
                      width: 152,
                      flex: 1,
                    }}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Status Doc
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      margin="dense"
                      label="statusdoc"
                      name="statusdoc"
                      value={statusdoc}
                      onChange={changeHandler}
                    >
                      <MenuItem value="">
                        <em>Status.....</em>
                      </MenuItem>

                      <MenuItem value={"Pendente"}>Pendente</MenuItem>
                      <MenuItem value={"Enviado"}>Enviado</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    name="dtstatusdoc"
                    id="datetime-local"
                    label="Data status Documento"
                    variant="filled"
                    margin="dense"
                    type="datetime-local"
                    value={dtstatusdoc}
                    onChange={onChangedtstatusdoc}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{
                      width: 245,
                    }}
                  />
                </div>

                {/*SITUACAO STATUS ENTREGA */}
                <div
                  style={{
                    display: "flex", // Permite que os itens fiquem lado a lado
                    flexDirection: "row", // Garante alinhamento horizontal
                    alignItems: "center", // Alinha verticalmente
                    gap: 10, // Espaçamento entre os itens
                    marginLeft: 20,
                  }}
                >
                  <FormControl
                    variant="filled"
                    style={{
                      width: 152,
                      flex: 1,
                    }}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Status Entrega
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      margin="dense"
                      label="statusentregacarga"
                      name="statusentregacarga"
                      value={statusentregacarga}
                      onChange={changeHandler}
                    >
                      <MenuItem value="">
                        <em>Status.....</em>
                      </MenuItem>

                      <MenuItem value={"Pendente"}>Pendente</MenuItem>
                      <MenuItem value={"Entregue"}>Entregue</MenuItem>
                      <MenuItem value={"Cancelado"}>Cancelado</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    name="dtentregacarga"
                    id="datetime-local"
                    label="Data status Entrega"
                    variant="filled"
                    margin="dense"
                    type="datetime-local"
                    value={dtentregacarga}
                    onChange={onChangedtentregacarga}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{
                      width: 245,
                    }}
                  />

                  <TextField
                    error={!/^\d{4}-\d{2}-\d{2}$/.test(dataentregacargapadrao)} // Mostra erro se o formato for inválido
                    helperText={
                      !/^\d{4}-\d{2}-\d{2}$/.test(dataentregacargapadrao)
                        ? "Insira a data no formato YYYY-MM-DD"
                        : ""
                    }
                    placeholder="YYYY-MM-DD"
                    name="dataentregacargapadrao"
                    id="datetime-local"
                    label="Data status Entrega padrao"
                    variant="filled"
                    margin="dense"
                    multiline
                    type="text"
                    value={dataentregacargapadrao}
                    onChange={onChangedtentregacargapadrao}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{
                      width: 245,
                    }}
                  />
                </div>
              </div>
              <br></br>
              <div style={{ padding: 10 }}>
                <Paper
                  style={{
                    //  maxHeight: 800, // Limita a altura máxima
                    width: "100%", // Adapta-se à largura da div pai
                    maxHeight: "100%", // Adapta-se à altura da div pai
                    minHeight: 350,
                    marginTop: 25,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: Cores.subcabecalho,
                      height: 15,
                      borderTopRightRadius: 5,
                      borderTopLeftRadius: 5,
                    }}
                  ></div>

                  <TableContainer
                    style={{
                      maxHeight: "300px", // Altura máxima definida
                      overflowY: "auto", // Habilita a barra de rolagem vertical
                    }}
                  >
                    <br></br>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="center"
                            style={{
                              backgroundColor: "#ffff",
                              color: "#333333",
                              fontWeight: "bold",
                            }}
                          >
                            Origem
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              backgroundColor: "#ffff",
                              color: "#333333",
                              fontWeight: "bold",
                            }}
                          >
                            Destino
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              backgroundColor: "#ffff",
                              color: "#333333",
                              fontWeight: "bold",
                            }}
                          >
                            Perfil
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              backgroundColor: "#ffff",
                              color: "#333333",
                              fontWeight: "bold",
                            }}
                          >
                            Qtd Pallet
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              backgroundColor: "#ffff",
                              color: "#333333",
                              fontWeight: "bold",
                            }}
                          >
                            Status Entrega
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              backgroundColor: "#ffff",
                              color: "#333333",
                              fontWeight: "bold",
                            }}
                          >
                            Data Entrega
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{
                              backgroundColor: "#ffff",
                              color: "#333333",
                              fontWeight: "bold",
                            }}
                          >
                            Opcoes
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      {listaEntregas.map((item, id) => (
                        <TableBody key={item.id}>
                          <TableRow role="checkbox" key={item.id}>
                            <TableCell align="center">
                              {item?.fkorigem?.descricao}
                            </TableCell>
                            <TableCell align="center">
                              {item?.fkdestino?.razao}
                            </TableCell>
                            <TableCell align="center">{item.perfil}</TableCell>
                            <TableCell align="center">
                              {item.qtdpallet}
                            </TableCell>
                            <TableCell align="center">
                              {item.statusentrega == "Pendente" ? (
                                <Typography
                                  style={{
                                    color: Cores.Vermelho1,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {item.statusentrega}
                                </Typography>
                              ) : (
                                <Typography
                                  style={{
                                    color: Cores.Verde,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {item.statusentrega}
                                </Typography>
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {(() => {
                                //FORMATAR DATA VISUALIZADO
                                let visualizado = item.dataentrega;
                                let hors = new Date(visualizado);
                                let hora = hors.getUTCHours();
                                let corrhora = hora;
                                let [date, month, year] = new Date(visualizado)
                                  .toLocaleDateString("pt-BR")
                                  .split("/");
                                let formatadevisualizado =
                                  date +
                                  "/" +
                                  month +
                                  "/" +
                                  year +
                                  " " +
                                  corrhora +
                                  ":" +
                                  hors.getUTCMinutes();

                                return formatadevisualizado;
                              })()}
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                variant="contained"
                                onClick={() => EditEntrega(item)}
                                style={{
                                  backgroundColor: Cores.Azul1,
                                  color: Cores.clBotoesExcluir,
                                  width: 150,
                                  marginRight: 5,
                                }}
                              >
                                Editar
                              </Button>
                              <Button
                                variant="contained"
                                onClick={() => removerItem(item.id)}
                                style={{
                                  backgroundColor: Cores.bgBotoesExcluir,
                                  color: Cores.clBotoesExcluir,
                                  width: 150,
                                }}
                              >
                                Excluir
                              </Button>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ))}
                    </Table>
                  </TableContainer>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 15, // Espaçamento entre linhas
                      justifyContent: "space-around",
                      marginTop: 45,
                      borderRadius: 5,
                      borderRadius: 5, // Borda arredondada
                      borderWidth: "1px", // Define a largura da borda
                      borderColor: Cores.Sinza2, // Define a cor da borda
                      borderStyle: "solid", // Define o estilo da borda
                      marginRight: 20,
                      marginLeft: 20,
                      padding: 8,
                    }}
                  >
                    {/* Primeira linha de itens */}
                    <div
                      style={{
                        display: "flex", // Permite que os itens fiquem lado a lado
                        flexDirection: "row", // Garante alinhamento horizontal
                        alignItems: "center", // Alinha verticalmente
                        gap: 10, // Espaçamento entre os itens
                      }}
                    >
                      <InputBase
                        style={{
                          color: "default",
                          height: 35,
                          width: 200,
                          backgroundColor: Cores.bgInpubase,
                        }}
                        type="text"
                        className="form-control text-center input-text"
                        placeholder="Selecione uma origem"
                        value={nomeOrigem}
                        InputProps={{
                          readOnly: true, // Campo somente leitura
                        }}
                      />
                      <BsSearch
                        color={Cores.bgBotoesNovo}
                        onClick={fetchOrigens}
                        style={{ fontSize: 20 }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex", // Permite que os itens fiquem lado a lado
                        flexDirection: "row", // Garante alinhamento horizontal
                        alignItems: "center", // Alinha verticalmente
                        gap: 10, // Espaçamento entre os itens
                        // width: 200,
                      }}
                    >
                      <InputBase
                        style={{
                          color: "default",
                          height: 35,
                          width: 250,
                          backgroundColor: Cores.bgInpubase,
                        }}
                        type="text"
                        className="form-control text-center input-text"
                        placeholder="Selecione uma destino"
                        value={nomedestinos}
                        InputProps={{
                          readOnly: true, // Campo somente leitura
                        }}
                      />
                      <BsSearch
                        color={Cores.bgBotoesNovo}
                        onClick={fetchDestinos}
                        style={{ fontSize: 20 }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex", // Permite que os itens fiquem lado a lado
                        flexDirection: "row", // Garante alinhamento horizontal
                        alignItems: "center", // Alinha verticalmente
                        gap: 10, // Espaçamento entre os itens
                        width: 200,
                      }}
                    >
                      <InputBase
                        style={{
                          color: "default",
                          height: 35,
                          width: 220,
                          backgroundColor: Cores.bgInpubase,
                        }}
                        type="text"
                        className="form-control text-center input-text"
                        placeholder="Selecione uma Perfil"
                        value={nomePerfil}
                        InputProps={{
                          readOnly: true, // Campo somente leitura
                        }}
                      />
                      <BsSearch
                        color={Cores.bgBotoesNovo}
                        onClick={fetchPerfil}
                        style={{ fontSize: 20 }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex", // Permite que os itens fiquem lado a lado
                        flexDirection: "row", // Garante alinhamento horizontal
                        alignItems: "center", // Alinha verticalmente
                        gap: 10, // Espaçamento entre os itens
                        width: 200,
                      }}
                    >
                      <InputBase
                        style={{
                          color: "default",
                          height: 35,
                          width: 200,
                          backgroundColor: Cores.bgInpubase,
                        }}
                        type="number"
                        className="form-control text-center input-text"
                        placeholder="Digite Qtd Pl"
                        name="qtdpl"
                        value={qtdpl}
                        onChange={changeHandler}
                      />
                    </div>

                    {/* Botão  */}
                    <Button
                      variant="contained"
                      onClick={preencherLista}
                      style={{
                        backgroundColor: Cores.bgBotoesNovo1,
                        color: Cores.clBotoesNovo,
                        marginRight: 100,
                        width: 150,
                      }}
                    >
                      Adicionar
                    </Button>
                  </div>
                  <div
                    style={{
                      marginTop: 100,

                      display: "flex", // Ativa o Flexbox
                      justifyContent: "center", // Alinha horizontalmente
                      alignItems: "center", // Alinha verticalmente
                      height: "100px",
                    }}
                  >
                    <Button
                      style={{
                        height: 30,
                        backgroundColor: Cores.bgBotoesNovo,
                        color: Cores.clBotoesNovo,
                      }}
                      variant="contained"
                      type="submit"
                      onClick={toEditProgramacao}
                    >
                      Salvar Programacao
                    </Button>
                  </div>
                </Paper>
              </div>

              <br></br>
              <br></br>
            </form>
          </Paper>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>

          {/* Modal MOTORISTA */}
          <Modal
            open={showModal}
            // onClose={() => this.setState({ showModal: false })}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Paper
              style={{
                padding: 20,
                maxWidth: 600,
                backgroundColor: "#ffffff",
              }}
            >
              {loading ? (
                <p>Carregando...</p>
              ) : (
                <div
                  style={{
                    backgroundColor: "#fff",

                    maxHeight: "70vh", // Limita a altura máxima do modal
                    overflowY: "auto", // Adiciona barra de rolagem vertical
                    width: "100%", // Largura ajustável do modal

                    borderRadius: "8px", // Estilo arredondado opcional
                  }}
                >
                  <h2>Lista de Motoristas</h2>
                  {/* Campo de pesquisa */}
                  <TextField
                    label="Pesquisar placa"
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ marginBottom: 20 }}
                    //    onChange={(e) =>                        this.setState({ filtramotorista: e.target.value })                     }
                  />
                  <ul>
                    {names
                      .filter((motorista) =>
                        motorista.nome
                          .toLowerCase()
                          .includes((filtramotorista || "").toLowerCase())
                      )
                      .map((motorista) => (
                        <li
                          key={motorista.id}
                          style={{
                            cursor: "pointer",
                            padding: "10px 0",
                            borderBottom: "1px solid #ddd",
                          }}
                          onClick={() =>
                            handleSelectMotorista(motorista.id, motorista.nome)
                          }
                        >
                          {motorista.nome} - {motorista.cpf}
                        </li>
                      ))}
                  </ul>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={FecharModalMotorista}
                  >
                    Fechar
                  </Button>
                </div>
              )}
            </Paper>
          </Modal>
          {/* Modal Veiculo trator */}
          <Modal
            open={modalVeiculoAberto}
            //  onClose={() => this.setState({ modalVeiculoAberto: false })}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Paper
              style={{
                padding: 20,
                maxWidth: 600,
                backgroundColor: "#ffffff",
              }}
            >
              {loading ? (
                <p>Carregando...</p>
              ) : (
                <div
                  style={{
                    backgroundColor: "#fff",

                    maxHeight: "70vh", // Limita a altura máxima do modal
                    overflowY: "auto", // Adiciona barra de rolagem vertical
                    width: "100%", // Largura ajustável do modal

                    borderRadius: "8px", // Estilo arredondado opcional
                  }}
                >
                  <h2>Lista de Veiculos</h2>
                  {/* Campo de pesquisa */}
                  <TextField
                    label="Pesquisar placa"
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ marginBottom: 20 }}
                    //   onChange={(e) => this.setState({ filtroPlaca: e.target.value })                     }
                  />
                  <ul>
                    {veiculotrator
                      .filter((veiculo) =>
                        veiculo.placa
                          .toLowerCase()
                          .includes((filtroPlaca || "").toLowerCase())
                      )
                      .map((placa) => (
                        <li
                          key={placa.id}
                          style={{
                            cursor: "pointer",
                            padding: "10px 0",
                            borderBottom: "1px solid #ddd",
                          }}
                          onClick={() =>
                            handleSelectVeiculotrator(placa.id, placa.placa)
                          }
                        >
                          Placa :{placa.placa} - Numero frota :{placa.nfrota}
                        </li>
                      ))}
                  </ul>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={FecharModalVeiculotrator}
                  >
                    Fechar
                  </Button>
                </div>
              )}
            </Paper>
          </Modal>
          {/* Modal Veiculo unidade carga */}
          <Modal
            open={showmodalunidadecarga}
            //  onClose={() => this.setState({ showmodalunidadecarga: false })}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Paper
              style={{
                padding: 20,
                maxWidth: 600,
                backgroundColor: "#ffffff",
              }}
            >
              {loading ? (
                <p>Carregando...</p>
              ) : (
                <div
                  style={{
                    backgroundColor: "#fff",
                    maxHeight: "70vh", // Limita a altura máxima do modal
                    overflowY: "auto", // Adiciona barra de rolagem vertical
                    width: "100%", // Largura ajustável do modal

                    borderRadius: "8px", // Estilo arredondado opcional
                  }}
                >
                  <h2>Lista de Veiculos</h2>
                  {/* Campo de pesquisa */}
                  <TextField
                    label="Pesquisar placa"
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ marginBottom: 20 }}
                    //    onChange={(e) => this.setState({ filtroUnidadecarga: e.target.value })                      }
                  />
                  <ul>
                    {unidadeCarga
                      .filter((veiculo) =>
                        veiculo.placa
                          .toLowerCase()
                          .includes((filtroPlaca || "").toLowerCase())
                      )
                      .map((placa) => (
                        <li
                          key={placa.id}
                          style={{
                            cursor: "pointer",
                            padding: "10px 0",
                            borderBottom: "1px solid #ddd",
                          }}
                          onClick={() =>
                            handleSelectunidadecarga(placa.id, placa.placa)
                          }
                        >
                          Placa :{placa.placa} - Numero frota :{placa.nfrota}
                        </li>
                      ))}
                  </ul>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={FecharModalunidadecarga}
                  >
                    Fechar
                  </Button>
                </div>
              )}
            </Paper>
          </Modal>

          {/* Modal   ORIGEM */}
          <Modal
            open={showModalOrigem}
            //  onClose={() => this.setState({ showModalOrigem: false })}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Paper
              style={{
                padding: 20,
                maxWidth: 600,
                backgroundColor: "#ffffff",
              }}
            >
              {loading ? (
                <p>Carregando...</p>
              ) : (
                <div
                  style={{
                    backgroundColor: "#fff",
                    maxHeight: "70vh", // Limita a altura máxima do modal
                    overflowY: "auto", // Adiciona barra de rolagem vertical
                    width: "100%", // Largura ajustável do modal
                    borderRadius: "8px", // Estilo arredondado opcional
                  }}
                >
                  <h2>Lista de Origens</h2>
                  {/* Campo de pesquisa */}
                  <TextField
                    label="Pesquisar origem"
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ marginBottom: 20 }}
                    onChange={handleFiltroOrigemChange}
                  />
                  <ul>
                    {origem
                      .filter((item) =>
                        item.descricao
                          .toLowerCase()
                          .includes((filtroOrigem || "").toLowerCase())
                      )
                      .map((item) => (
                        <li
                          key={item.id}
                          style={{
                            cursor: "pointer",
                            padding: "10px 0",
                            borderBottom: "1px solid #ddd",
                          }}
                          onClick={() =>
                            handleSelectOrigem(item.id, item.descricao)
                          }
                        >
                          Descricao :{item.descricao} - Razao:{item.razao}
                        </li>
                      ))}
                  </ul>
                  <Button
                    variant="contained"
                    color="secondary"
                    //  onClick={() => this.setState({ showModalOrigem: false })}
                  >
                    Fechar
                  </Button>
                </div>
              )}
            </Paper>
          </Modal>
          {/* Modal   DESTINOS */}
          <Modal
            open={showModalDestinos}
            //  onClose={() => this.setState({ showModalDestinos: false })}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Paper
              style={{
                padding: 20,
                maxWidth: 600,
                backgroundColor: "#ffffff",
              }}
            >
              {loading ? (
                <p>Carregando...</p>
              ) : (
                <div
                  style={{
                    backgroundColor: "#fff",
                    maxHeight: "70vh", // Limita a altura máxima do modal
                    overflowY: "auto", // Adiciona barra de rolagem vertical
                    width: "100%", // Largura ajustável do modal
                    borderRadius: "8px", // Estilo arredondado opcional
                  }}
                >
                  <h2>Lista de Destinos</h2>
                  {/* Campo de pesquisa */}
                  <TextField
                    label="Pesquisar Destinos"
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ marginBottom: 20 }}
                    onChange={handlefiltroDestinosChange}
                  />
                  <ul>
                    {destinos
                      .filter((item) =>
                        item.razao
                          .toLowerCase()
                          .includes((filtroDestinos || "").toLowerCase())
                      )
                      .map((item) => (
                        <li
                          key={item.id}
                          style={{
                            cursor: "pointer",
                            padding: "10px 0",
                            borderBottom: "1px solid #ddd",
                          }}
                          onClick={() =>
                            handleSelectDestinos(item.id, item.razao)
                          }
                        >
                          Lojas :{item.lojas} - Razao:{item.razao}
                        </li>
                      ))}
                  </ul>
                  <Button
                    variant="contained"
                    color="secondary"
                    // onClick={() => this.setState({ showModalDestinos: false })}
                  >
                    Fechar
                  </Button>
                </div>
              )}
            </Paper>
          </Modal>

          {/* Modal   PERFIL */}
          <Modal
            open={showModalPerfil}
            onClose={() => this.setState({ showModalPerfil: false })}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Paper
              style={{
                padding: 20,
                maxWidth: 600,
                backgroundColor: "#ffffff",
              }}
            >
              {loading ? (
                <p>Carregando...</p>
              ) : (
                <div
                  style={{
                    backgroundColor: "#fff",
                    maxHeight: "70vh", // Limita a altura máxima do modal
                    overflowY: "auto", // Adiciona barra de rolagem vertical
                    width: "100%", // Largura ajustável do modal
                    borderRadius: "8px", // Estilo arredondado opcional
                  }}
                >
                  <h2>Lista de perfil</h2>
                  {/* Campo de pesquisa */}
                  <TextField
                    label="Pesquisar Destinos"
                    variant="outlined"
                    fullWidth
                    size="small"
                    style={{ marginBottom: 20 }}
                    onChange={handlefiltroPerfilChange}
                  />
                  <ul>
                    {perfil
                      .filter((item) =>
                        item.perfil
                          .toLowerCase()
                          .includes((filtroPerfil || "").toLowerCase())
                      )
                      .map((item) => (
                        <li
                          key={item.id}
                          style={{
                            cursor: "pointer",
                            padding: "10px 0",
                            borderBottom: "1px solid #ddd",
                          }}
                          onClick={() =>
                            handleSelectPerfil(item.id, item.perfil)
                          }
                        >
                          Perfil :{item.perfil}
                        </li>
                      ))}
                  </ul>
                  <Button
                    variant="contained"
                    color="secondary"
                    //  onClick={() => this.setState({ showModalPerfil: false })}
                  >
                    Fechar
                  </Button>
                </div>
              )}
            </Paper>
          </Modal>

          {/* Modal MOTORISTA */}
          <Modal
            open={showModalEntregas}
            // onClose={() => this.setState({ showModal: false })}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Paper
              style={{
                padding: 20,
                backgroundColor: "#ffffff",
                height: "70%", // Altura ajustada para 70% da tela
                width: "70%", // Largura ajustada para 70% da tela
                borderRadius: "8px", // Estilo arredondado opcional
              }}
            >
              <div
                style={{
                  backgroundColor: "#fff",
                  overflowY: "auto", // Adiciona barra de rolagem vertical
                  height: "100%", // Preenche o tamanho do Paper
                  borderRadius: "8px", // Estilo arredondado opcional
                }}
              >
                <h2>Entrega</h2>
                {/* Campo de pesquisa */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",

                    marginTop: 20,
                  }}
                >
                  <div
                    style={{
                      gap: 10, // Espaçamento entre os itens
                      marginRight: 35,
                    }}
                  >
                    <Typography
                      style={{
                        color: Cores.Sinza1,
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      Origem :
                    </Typography>
                    <InputBase
                      disabled
                      style={{
                        color: "default",
                        height: 35,
                        width: 200,
                        backgroundColor: Cores.bgInpubase,
                        marginRight: 10,
                      }}
                      type="text"
                      className="form-control text-center input-text"
                      placeholder="Selecione uma origem"
                      value={nomeOrigem}
                      InputProps={{
                        readOnly: true, // Campo somente leitura
                      }}
                    />
                    {/* 
                    <BsSearch
                      color={Cores.bgBotoesNovo}
                      onClick={fetchOrigens}
                      style={{ fontSize: 20 }}
                    />
                     */}
                  </div>
                  <div>
                    <Typography
                      style={{
                        color: Cores.Sinza1,
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      Destino :
                    </Typography>
                    <InputBase
                      disabled
                      style={{
                        color: "default",
                        height: 35,
                        width: 300,
                        backgroundColor: Cores.bgInpubase,
                        marginRight: 10,
                      }}
                      type="text"
                      className="form-control text-center input-text"
                      placeholder="Selecione uma origem"
                      value={nomedestinos}
                      InputProps={{
                        readOnly: true, // Campo somente leitura
                      }}
                    />
                    {/* 
                    <BsSearch
                      color={Cores.bgBotoesNovo}
                      onClick={fetchDestinos}
                      style={{ fontSize: 20 }}

                    />
                     */}
                  </div>
                  <div
                    style={{
                      gap: 10, // Espaçamento entre os itens
                      marginLeft: 50,
                    }}
                  >
                    <Typography
                      style={{
                        color: Cores.Sinza1,
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      Perfil :
                    </Typography>
                    <InputBase
                      style={{
                        color: "default",
                        height: 35,
                        width: 160,
                        backgroundColor: Cores.bgInpubase,
                        marginRight: 10,
                      }}
                      type="text"
                      className="form-control text-center input-text"
                      placeholder="Selecione uma Perfil"
                      value={nomePerfil}
                      InputProps={{
                        readOnly: true, // Campo somente leitura
                      }}
                    />
                    <BsSearch
                      color={Cores.bgBotoesNovo}
                      onClick={fetchPerfil}
                      style={{ fontSize: 20 }}
                    />
                  </div>
                  <div
                    style={{
                      marginLeft: 50,
                    }}
                  >
                    <Typography
                      style={{
                        color: Cores.Sinza1,
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      Qtd Pallet :
                    </Typography>
                    <InputBase
                      style={{
                        color: "default",
                        height: 35,
                        width: 200,
                        backgroundColor: Cores.bgInpubase,
                      }}
                      type="number"
                      className="form-control text-center input-text"
                      placeholder="Digite Qtd Pl"
                      name="qtdpl"
                      value={qtdpl}
                      onChange={changeHandler}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 40,
                  }}
                >
                  <FormControl
                    variant="filled"
                    style={{
                      width: 200,
                      marginTop: 5,
                    }}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Status Entrega
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      margin="dense"
                      label="statusentrega"
                      name="statusentrega"
                      value={statusentrega}
                      onChange={changeHandler}
                    >
                      <MenuItem value="">
                        <em>Status.....</em>
                      </MenuItem>

                      <MenuItem value={"Pendente"}>Pendente</MenuItem>
                      <MenuItem value={"Entregue"}>Entregue</MenuItem>
                      <MenuItem value={"Cancelado"}>Cancelado</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    name="dataentrega"
                    id="datetime-local"
                    label="Data Entrega"
                    variant="filled"
                    margin="dense"
                    type="datetime-local"
                    value={dataentrega}
                    onChange={onChangedataentrega}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{
                      width: 245,
                      marginLeft: 60,
                    }}
                  />
                  <TextField
                    error={!/^\d{4}-\d{2}-\d{2}$/.test(dataentregapadrao)} // Mostra erro se o formato for inválido
                    helperText={
                      !/^\d{4}-\d{2}-\d{2}$/.test(dataentregapadrao)
                        ? "Insira a data no formato YYYY-MM-DD"
                        : ""
                    }
                    placeholder="YYYY-MM-DD"
                    name="dataentregapadrao"
                    id="dataentregapadrao"
                    label="Data Entrega padrao"
                    variant="filled"
                    margin="dense"
                    multiline
                    type="text"
                    value={dataentregapadrao}
                    onChange={onChangedataentregapadrao}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{
                      width: 245,
                      marginLeft: 50,
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  alignContent: "center",
                  alignItems: "center",
                  justifyItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Button
                  style={{
                    backgroundColor: Cores.Azul2,
                    color: Cores.Branco1,
                    marginTop: -50,
                    marginRight: 10,
                  }}
                  variant="contained"
                  onClick={SalvarAlteracaoEntrega}
                >
                  Salvar
                </Button>
                <Button
                  style={{
                    backgroundColor: Cores.Vermelho2,
                    color: Cores.Branco1,
                    marginTop: -50,
                  }}
                  variant="contained"
                  onClick={FecharModalEntrega}
                >
                  Fechar
                </Button>
              </div>
            </Paper>
          </Modal>
        </div>
      </div>
    </body>
  );
}
